<template>
    <div class="pressure-record-edit-parameter-cpap">
        <div class="pressure-record-edit-parameter-cpap-top">
            <div class="pressure-record-edit-parameter-cpap-column" style="margin-right: 25px;">
                <record-selector title="起始压力" v-model="startPressure" :dataArray="startPressureArray"></record-selector>
                <record-selector title="呼气释压" v-model="breathPressure" :dataArray="breathPressureArray"></record-selector>
            </div>
            <div class="pressure-record-edit-parameter-cpap-column">
                <record-selector title="治疗压力" v-model="curePressure" :dataArray="curePressureArray"></record-selector>
                <record-selector title="释压水平" :disabled="breathPressure == 0" v-model="pressureLevel"
                    :dataArray="pressureLevelArray"></record-selector>
            </div>
        </div>
        <common-bottom ref="bottomRef" :cureMode="cureMode" :initParamModel="initParamModel"></common-bottom>
    </div>
</template>

<script>
import RecordSelector from "./component/pop-view-holography-case-edit-parameter-selector.vue";
import RecordSelectorMultiple from "./component/pop-view-holography-case-edit-parameter-selector-multiple.vue";
import CommonBottom from "./bottom.vue";
import { pressureArray, pressureLevelArray, breathPressureArray } from "@js/parameter-option-data-source.js";
export default {

    components: {
        RecordSelector,
        RecordSelectorMultiple,
        CommonBottom
    },

    props: {
        initParamModel: Object
    },

    data() {
        return {
            cureMode: CURE_MODE.CPAP,
            paramModel: undefined,
            //选中项
            startPressure: undefined,
            curePressure: undefined,
            breathPressure: undefined,
            pressureLevel: undefined,
            //选项数组
            breathPressureArray: breathPressureArray,
            pressureLevelArray: pressureLevelArray,
            //ref
            bottomRef: undefined
        }
    },

    computed: {
        //选项数组
        startPressureArray() {
            return pressureArray(4, this.curePressure)
        },

        curePressureArray() {
            return pressureArray(this.startPressure, 20)
        },

        jsonStr() {
            return JSON.stringify({
                cureMode: this.cureMode,
                startPressure: this.startPressure * 10,
                cpapP: this.curePressure * 10,
                depType: this.breathPressure,
                depLevel: this.pressureLevel,
                cureDelay: this.$refs.bottomRef.delayPressure,
                humidifyLevel: this.$refs.bottomRef.wetLevel,
                intelligentStart: this.$refs.bottomRef.smartStart,
                intelligentStop: this.$refs.bottomRef.smartStop,
                leakAlarm: this.$refs.bottomRef.leakRemindTime,
                maskTypeId: this.$refs.bottomRef.maskType,
                mask: this.$refs.bottomRef.maskCategory,
                pipe: this.$refs.bottomRef.tubeType,
                heattubeTem: this.$refs.bottomRef.tubeType == 0 ? 0 : this.$refs.bottomRef.heatTubeTemperature
            })
        }
    },

    async created() {
        this.paramModel = { ...this.initParamModel }
        if (this.paramModel.cureMode == this.cureMode) {
            this.paramModel = this.initParamModel
            this.startPressure = parseFloat(this.paramModel.startPressure / 10)
            this.curePressure = parseFloat(this.paramModel.cpapP / 10)
            this.breathPressure = parseInt(this.paramModel.depType)
            this.pressureLevel = parseInt(this.paramModel.depLevel)
        } else {
            this.paramModel.cureMode = this.cureMode
            this.startPressure = 4
            this.curePressure = 8
            this.breathPressure = 1
            this.pressureLevel = 1
        }
    },

    mounted() {
        this.bottomRef = this.$refs.bottomRef
    }

}
</script>

<style lang="scss" scoped>
.pressure-record-edit-parameter-cpap {
    &-top {
        display: flex;
    }

    &-bottom {
        display: flex;
    }

    &-column {
        width: 50%;
    }
}
</style>