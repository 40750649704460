<template>
    <div class="pressure-record-edit-parameter-bottom">
        <div class="pressure-record-edit-parameter-bottom-column" style="margin-right: 25px;">
            <record-selector title="延迟升压" v-model="delayPressure" :dataArray="delayPressureArray"></record-selector>
            <record-selector title="智能启动" v-model="smartStart" :dataArray="smartStartArray"></record-selector>
            <record-selector title="漏气提示时间" v-model="leakRemindTime" :dataArray="leakRemindTimeArray"></record-selector>
            <record-selector title="管道类型" v-model="tubeType" :dataArray="tubeTypeArray"></record-selector>
        </div>
        <div class="pressure-record-edit-parameter-bottom-column">
            <record-selector title="湿化等级" v-model="wetLevel" :dataArray="wetLevelArray"></record-selector>
            <record-selector title="智能停止" v-model="smartStop" :dataArray="smartStopArray"></record-selector>
            <record-selector title="面罩类型" v-model="maskType" :dataArray="asyncMaskTypeArray"
                @onChanged="onMaskTypeSelected"></record-selector>
            <record-selector v-if="tubeType == 1" title="加温温度" v-model="heatTubeTemperature"
                :dataArray="heatTubeTemperatureArray"></record-selector>
        </div>
    </div>
</template>

<script>
import RecordSelector from "./component/pop-view-holography-case-edit-parameter-selector.vue";
import RecordSelectorMultiple from "./component/pop-view-holography-case-edit-parameter-selector-multiple.vue";
import { wetLevelArray, delayPressureArray, leakRemindTimeArray, maskTypeArray, smartStartArray, smartStopArray, tubeTypeArray, heatTubeTemperatureArray } from "@js/parameter-option-data-source.js";
export default {

    components: {
        RecordSelector,
        RecordSelectorMultiple
    },

    props: {
        initParamModel: Object,
        cureMode: Number
    },

    data() {
        return {
            paramModel: undefined,
            wetLevel: undefined,
            delayPressure: undefined,
            smartStart: undefined,
            smartStop: undefined,
            leakRemindTime: undefined,
            maskType: undefined,
            maskCategory: undefined,
            tubeType: undefined,
            heatTubeTemperature: undefined,
            //选项数组
            wetLevelArray: wetLevelArray,
            delayPressureArray: delayPressureArray(),
            leakRemindTimeArray: leakRemindTimeArray,
            asyncMaskTypeArray: [],
            smartStartArray: smartStartArray,
            smartStopArray: smartStopArray,
            tubeTypeArray: tubeTypeArray,
            heatTubeTemperatureArray: heatTubeTemperatureArray()
        }
    },

    async created() {
        this.asyncMaskTypeArray = await maskTypeArray()
        this.paramModel = this.initParamModel
        if (this.paramModel && this.paramModel.cureMode == this.cureMode) {
            this.delayPressure = parseInt(this.paramModel.cureDelay)
            this.wetLevel = parseInt(this.paramModel.humidifyLevel)
            this.smartStart = parseInt(this.paramModel.intelligentStart)
            this.smartStop = parseInt(this.paramModel.intelligentStop)
            this.leakRemindTime = parseInt(this.paramModel.leakAlarm)
            this.maskType = parseInt(this.paramModel.maskTypeId)
            this.maskCategory = parseInt(this.paramModel.mask)
            this.tubeType = parseInt(this.paramModel.pipe)
            this.heatTubeTemperature = parseInt(this.paramModel.heattubeTem)
        } else {
            this.delayPressure = 10
            this.wetLevel = 2
            this.smartStart = 1
            this.smartStop = 0
            this.leakRemindTime = 0
            this.maskType = 13
            this.maskCategory = 0
            this.tubeType = 0
            this.heatTubeTemperature = this.tubeType == 1 ? 20 : 0
        }
    },


    watch: {
        tubeType(value) {
            //加温管
            if (value == 1 && this.heatTubeTemperature == 0) {
                this.heatTubeTemperature = 20
            }
        }

    },

    methods: {
        onMaskTypeSelected(index) {
            this.maskCategory = this.asyncMaskTypeArray[index - 1]?.maskCategory
        }
    }

}
</script>

<style lang="scss" scoped>
.pressure-record-edit-parameter-bottom {
    display: flex;

    &-column {
        width: 50%;
    }
}
</style>