
<template>
    <div class="record-selector">
        <p class="record-selector-title">{{ title }}:</p>
        <el-select class="record-selector-content" v-model="values" multiple collapse-tags :popper-append-to-body="false">
            <el-option v-for="item in dataArray" :key="item.value" :label="item.title" :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
import { Select, Option } from "element-ui";
export default {

    model: {
        prop: "initValues",
        event: "onChanged"
    },

    components: {
        elSelect: Select,
        elOption: Option,
    },

    props: {
        title: String,
        dataArray: Array,
        initValues: Array
    },

    data() {
        return {
            values: []
        }
    },

    mounted() {
        this.values = this.initValues
    },

    watch: {
        initValues(value) {
            this.values = value

        },

        values(value) {
            this.$emit("onChanged", value)
        }
    }
}
</script>

<style lang="scss" scoped>
.record-selector {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-title {
        color: black;
        font-size: 16px;
        margin-right: 10px;
    }

    &-content {
        flex: 1;

        /deep/.el-input--suffix .el-input__inner {
            font-size: 16px;
        }

        /deep/.el-select-dropdown__item {
            font-size: 16px;
        }
    }

}
</style>